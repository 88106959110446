<template>
  <div id="centreLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2" style="font-size: 24px">产量统计</span>
          <dv-decoration-3
            style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            "
          />
        </div>
      </div>
      <div class="d-flex jc-center">
        <!-- <CentreLeft1Chart /> -->
      </div>
      <!-- 4个主要的数据 -->
      <div
        v-for="(item, index) in numberData"
        :key="index"
      >
        <div class="bottom-data"  v-show="showindex==index+1">
          <div>
            <span
              class="fs-xl text mx-2"
              style="font-size: 36px; color: #ffc107;line-height: 50px;"
            >
              {{ item.text }}</span
            >
          </div>
          <div
            class="item-box"
            v-for="(vaue, index1) in item.data"
            :key="index1"
          >
            <div
              class="d-flex colorYellow"
              style="
                font-size: 32px;
                height: 15px;
                margin-top: 6px;
                margin-left: 33px;
              "
            >
              {{ vaue.value }}
            </div>
            <p
              class="text"
              style="
                text-align: center;
                font-size: 22px;
                height: 30px;
                margin-top: 12px;
                margin-left: 21px;
              "
            >
              {{ vaue.name }}
            </p>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
// import CentreLeft1Chart from "@/components/echart/centerLeft/centerLeft1Chart";
import { selectProductionTotal } from "../libs/axios";
export default {
  data() {
    return {
       showindex:0,
      maxindex:0,
      config: {
        lineWidth: 30,
        activeRadius: "80%",
        radius: "75%",
        activeTimeGap: 2000,
      },
      numberData: [],
    };
  },
  components: {
    // CentreLeft1Chart
  },
  mounted() {
    this.init();
    setInterval(this.init, 10000);
  },
 
  methods: {
    init() {
      this.getDay7WageEchartsList();
    },
    getDay7WageEchartsList() {
       if(this.showindex==this.maxindex)
        {
          this.showindex=0;
        }
        this.showindex++;
      this.loading = true;
      var deptId=this.$store.state.deptId;
      selectProductionTotal(deptId).then((res) => {
        // vm.$store.commit("setMessageType", res.result);
        //  alert(JSON.stringify(res.data));
        this.loading = false;
        this.numberData = res.data;
         this.maxindex=res.data.length;
      });
    },
  },
};
</script>

<style lang="scss">
#centreLeft1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 2.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }

  .bottom-data {
    .item-box {
      float: left;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      // 金币
      .coin {
        position: absolute;
        left: 0.1rem;
        top: 0.2125rem;
        font-size: 0.25rem;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
    }
  }
}
</style>